
import { defineComponent, ref } from "vue";
import { keyBy, get } from "lodash";
import { format } from "date-fns";
import { useRoute } from "@/helpers";
import { Avatar3 } from "@/uikit";
import { ERoutesName } from "@/router";

export default defineComponent({
  components: { Avatar3 },
  setup() {
    const { push, query, back } = useRoute();
    const certificates = ref<any[]>([]);
    const certificat = ref();
    const _certificates = localStorage.getItem("certificates");
    if (_certificates) (certificates.value = JSON.parse(_certificates)), "id";
    if (!query.id || !certificates.value[Number(query.id)]) back();
    else certificat.value = certificates.value[Number(query.id)];
    const handleViewFull = (link: string) =>
      push({ name: ERoutesName.VIEW_IMAGE, query: { link } });
    return { certificat, format, handleViewFull, back };
  }
});
